<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="User requests">
                <delete-items-button v-if="$hasPermission($permissions.RemoveUserRequests)" :selection="selection" @deleted="loadData" @reset="selection.selected_item_ids = []"/>


                <basic-table ref="basicTable"  :columns="columns" :data="userRequests" no-data-text="No requests open 🎉" v-slot="props" @update-selection="updateSelection" @filter-update="filterUpdate">
                    <div v-if="props.column.displayType === 4 && $hasPermission($permissions.RemoveUserRequests)" class="d-flex justify-content-center">
                        <b-form-checkbox  v-model="selection.selected_item_ids" :value="props.row.id"></b-form-checkbox>
                    </div>
                    <div v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button variant="warning" @click="closeRequest(props.row.id)" v-if="props.row.request_status === 0" class="mr-1">
                            <feather-icon icon="StopCircleIcon"/>
                        </b-button>
                        <b-button variant="danger" @click="removeRequest(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <span v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <b-badge v-if="props.row.request_status === 0" variant="light-warning">
                            Open
                        </b-badge>
                        <b-badge v-else variant="light-primary">
                            Closed
                        </b-badge>
                    </span>
                    <span v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                        <b-link :to="(props.row.type === 0) ? `/outlet/${props.row.outlet_id}` : `/promotional_item/${props.row.promotional_item_id}`">{{ props.formattedRow[props.column.field] }}</b-link>
                    </span>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import {BButton, BCard, BOverlay, BBadge, BLink, BFormCheckbox} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import DeleteItemsButton from '@/views/components/DeleteItemsButton.vue'

    export default {
        components: {
            DeleteItemsButton,
            BCard,
            BOverlay,
            BBadge,
            BButton,
            BasicTable,
            BLink,
            BFormCheckbox
        },
        data() {
            return {
                dataLoaded: false,
                userRequestsRaw: [],
                selection:{
                    selected_item_ids:[],
                    collection_name:''
                },

                columns: [
                    {
                        label: 'Select',
                        displayType: 4,
                        field: 'select',
                        sortable: false
                    },
                    {
                        label: 'Timestamp',
                        displayType: 0,
                        field: 'timestamp',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Status',
                        displayType: 2,
                        field: 'status',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Type',
                        displayType: 0,
                        field: 'type_text',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Item',
                        displayType: 3,
                        field: 'item',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Username',
                        displayType: 0,
                        field: 'username',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'user_name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Last name',
                        displayType: 0,
                        field: 'user_last_name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Request',
                        displayType: 0,
                        field: 'request',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                newRequestModalActive: false,
                addObject: {
                    request: ''
                }
            }
        },
        methods: {
            loadData() {
                this.$refs.basicTable.$refs.table.reset()
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/user_request')
                loadPromise.then(function(response) {
                    thisIns.userRequestsRaw = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            closeRequest(id) {
                const thisIns = this
                const closePromise = this.$http.put(`/api/management/v1/user_request/${id}/close`)
                closePromise.then(function() {
                    thisIns.$printSuccess('Request closed')
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            async removeRequest(id) {
                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to remove this request?')
                    
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/user_request/${id}`)
                    this.$printSuccess('Request removed')
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)                    
                } finally {
                    this.loadData()
                }
            },
            updateSelection(e) {
                if (this.$refs.basicTable.$refs.table.paginated[0] && this.$refs.basicTable.$refs.table.processedRows[0] && e) {
                    let arr = []
                    const pagResults = this.$refs.basicTable.$refs.table.paginated[0].children
                    const filterResults = this.$refs.basicTable.$refs.table.processedRows[0].children

                    arr = pagResults.filter(element => filterResults.includes(element))
                    arr.forEach((item) => {
                        const el =  this.selection.selected_item_ids.includes(item.id)
                        if (!el)   this.selection.selected_item_ids.push(item.id)
                    })
                } else {
                    this.selection.selected_item_ids = []
                }
            },
            filterUpdate(e) {
                e.forEach((item) => {
                    const el =  this.selection.selected_item_ids.includes(item.id)
                    if (el)   this.selection.selected_item_ids.splice(item.id)
                })
                this.$store.dispatch('app/updateSelectAll', false)
            }
        },
        computed: {
            userRequests() {
                return this.userRequestsRaw.map((request, index) => {
                    return {
                        index,
                        id: request.id,
                        outlet_id: request.outlet_id,
                        promotional_item_id: request.promotional_item_id,
                        timestamp: this.moment(request.timestamp).format('YYYY/MM/DD HH:mm'),
                        type: (request.outlet.length > 0) ? 0 : 1,
                        type_text: (request.outlet.length > 0) ? 'Outlet' : 'Promotional item',
                        item: (request.outlet.length > 0) ? request.outlet : request.promotional_item,
                        request_status: request.request_status,
                        status: (request.request_status === 0) ? 'Open' : 'Closed',
                        username: request.username,
                        user_name: request.user_name,
                        user_last_name: request.user_last_name,
                        request: request.request
                    }
                })
            }
        },
        mounted() {
            this.selection.collection_name = 'user_requests'
            this.loadData()
        }
    }
</script>